<template>
	<section
		data-component="sidebar-list"
	>
		<h1
			data-element="heading"
		>
			{{ heading }}
		</h1>
		<div data-element="list-items">
			<router-link
				data-element="list-item"
				v-for="(listItem, key) in listItems"
				:key="key"
				:to="listItem.route"
			>
				<icon
					:icon="listItemIcon"
					color="dark-navy"
				/>
				<span>
					{{ listItem.text }}
				</span>
			</router-link>
		</div>
		<router-link
			v-if="getShowMoreLink"
			data-element="more-link"
			:to="moreLinkRoute"
		>
			<span>
				{{ moreLinkText }}
			</span>
			<icon
				icon="chevron-left"
				colour="dark-navy"
			/>
		</router-link>
	</section>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			heading: {
				type: String,
				default: ''
			},
			listItems: {
				type: Array,
				default: () => ([])
			},
			listItemIcon: {
				type: String,
				default: 'book'
			},
			moreLinkText: {
				type: String,
				default: ''
			},
			moreLinkRoute: {
				type: String,
				default: ''
			}
		},
		data: () => ({}),
		computed: {
			getShowMoreLink () {
				if (!this.moreLinkText?.length ||
						!this.moreLinkRoute?.length) {
					return false;
				}
				return true;
			}
		},
		methods: {
			getArticleRoute (article) {
				if (!this.getCategoryRoute ||
						!article?.slug) {
					return false;
				}
				const articleSlug = article.slug;
				return `${this.getCategoryRoute}/${articleSlug}`;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='sidebar-list'] {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $c-light-navy;
    padding: 16px;

		[data-element='heading'] {
			margin-bottom:rem(16);
      @include font(24px, $c-darkest-navy, 700, 28px)
		}
		[data-element='list-items'] {
			margin-bottom:rem(16);
			&:last-child {
				margin-bottom:0;
			}
			[data-element='list-item'] {
				display:flex;
				align-items:center;
				margin-bottom:rem(4);
        padding: 4px;

				&:last-child {
					margin-bottom:0;
				}
				[data-component='icon'] {
					flex-shrink:0;
					width:rem(16);
					height:auto;
					margin-right:rem(5);
				}
				span {
          @include font(16px, $c-dark-navy, 500, 18px)
				}

        &:hover {
          background: $c-white-d;
          border-radius: 4px;
        }

			}
		}
		[data-element='more-link'] {
			display:flex;
			align-items:center;
			span {
				@include font(16px, $c-dark-navy, 500, 18px)
			}
			[data-component='icon'] {
				flex-shrink:0;
				width:rem(18);
				height:auto;
				transform:rotate(180deg);
			}
		}
	}

</style>
